// StepForm.js
import React, { useState, useEffect } from 'react';

import axios from 'axios';
import PersonalDetails from './PersonalDetails';
import ContactDetails from './ContactDetails';
import BasicInformation from './BasicInformation';
import RankDetails from './RankDetails';
import VesselDetails from './VesselDetails';
import ExperienceDetails from './ExperienceDetails';
import CertificateDetails from './CertificateDetails';
import UploadDocuments from './UploadDocuments';
import ProgressBar from './ProgressBar';



const StepForm = () => {

    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        // Personal Details
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        age: '',

        // Contact Details
        email: '',
        mobileNumber: '',
        whatsappNumber: '',
        gender: '',

        // Basic Information
        nationality: '',
        dateOfAvailability: '',
        sid: '',
        usVisa: '',

        // Rank Details 
        lastRank: '',
        appliedRank: '',

        // Vessel Details
        lastVessel: '',
        appliedVessel: '',
        vesselExperience: [],

        // Experience Details
        totalSeaExperience: '',
        lastRankExperience: '',

        // Certificate Details
        cop: '',
        coc: '',
        watchKeeping: '',

        // Documents
        profilePicture: null,
        resume: null
    });

    // COC | COP | WATCHKEEPING | RANK | VESSEL | NATIONALITY | GENDER 
    const [copOptions, setCopOptions] = useState([]);
    const [cocOptions, setCocOptions] = useState([]);
    const [shipOptions, setShipOptions] = useState([]);
    const [watchKeepingOptions, setWatchKeepingOptions] = useState([]);
    const [rankOptions, setRankOptions] = useState([]);
    const [vesselExpOptions, setVesselExpOptions] = useState([]);
    const [nationalityOptions, setNationalityOptions] = useState([]);
    const [genderOptions] = useState([
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Other', label: 'Other' },
    ]);

    const nextStep = () => {
        setCurrentStep(prev => prev + 1);
        console.log(formData);
    };

    const prevStep = () => {
        setCurrentStep(prev => prev - 1);
        console.log(formData);
    };

    const handleInputChange = (name, value) => {
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Fetch Attribute Data
    useEffect(() => {
        
        const fetchAttributes = async () => {
          try {
            const response = await axios.post(`https://api.rightships.com/attributes/get`, {}, {
              headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'User-Agent': 'Thunder Client (https://www.thunderclient.com)',
              }
            });
    
            if (response.data && response.data.code === 200) {
              const attributes = response.data.data;
    
              const copAttribute = attributes.find(attr => attr.name.toLowerCase() === 'cop');
              const cocAttribute = attributes.find(attr => attr.name.toLowerCase() === 'coc');
              const shipAttribute = attributes.find(attr => attr.name.toLowerCase() === 'ships');
              const watchKeepingAttribute = attributes.find(attr => attr.name.toLowerCase() === 'watch keeping');
              const rankAttribute = attributes.find(attr => attr.name.toLowerCase() === 'rank');
              const nationalityAttribute = attributes.find(attr => attr.name.toLowerCase() === 'nationality');
    
              const copData = copAttribute ? copAttribute.values : [];
              const cocData = cocAttribute ? cocAttribute.values.sort((a, b) => a.localeCompare(b)) : [];
              const shipData = shipAttribute ? shipAttribute.values.sort((a, b) => a.localeCompare(b)) : [];
              const watchKeepingData = watchKeepingAttribute ? watchKeepingAttribute.values : [];
              const rankData = rankAttribute ? rankAttribute.values.sort((a, b) => a.localeCompare(b)) : [];
              const nationalityData = nationalityAttribute ? nationalityAttribute.values.sort((a, b) => a.localeCompare(b)) : [];
    
              setCopOptions(copData.map(option => ({ value: option, label: option })));
              setCocOptions(cocData.map(option => ({ value: option, label: option })));
              setShipOptions(shipData.map(option => ({ value: option, label: option })));
              setWatchKeepingOptions(watchKeepingData.map(option => ({ value: option, label: option })));
              setRankOptions(rankData.map(option => ({ value: option, label: option })));
              setVesselExpOptions(shipData.map(option => ({ value: option, label: option })));
              setNationalityOptions(nationalityData.map(option => ({ value: option, label: option }))); 

            } else {
              console.error('Failed to fetch attributes:', response.data.msg);
            }
          } catch (error) {
            console.error('Failed to fetch attributes:', error);
          }
        };
    
        fetchAttributes();

      }, []);

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return <PersonalDetails
                    formData={formData}
                    handleInputChange={handleInputChange}
                    nextStep={nextStep}
                />;
            case 2:
                return <ContactDetails
                    formData={formData}
                    handleInputChange={handleInputChange}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    genderOptions={genderOptions}
                />;
            case 3:
                return <BasicInformation
                    formData={formData}
                    handleInputChange={handleInputChange}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    nationalityOptions={nationalityOptions}
                />;
            case 4:
                return <RankDetails
                    formData={formData}
                    handleInputChange={handleInputChange}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    rankOptions={rankOptions}
                />;
            case 5:
                return <VesselDetails
                    formData={formData}
                    handleInputChange={handleInputChange}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    vesselExpOptions={vesselExpOptions}
                />;
            case 6:
                return <ExperienceDetails
                    formData={formData}
                    handleInputChange={handleInputChange}
                    nextStep={nextStep}
                    prevStep={prevStep}
                />;
            case 7:
                return <CertificateDetails
                    formData={formData}
                    handleInputChange={handleInputChange}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    copOptions={copOptions}
                    cocOptions={cocOptions}
                    watchKeepingOptions={watchKeepingOptions}
                />;
            case 8:
                return <UploadDocuments
                    formData={formData}
                    handleInputChange={handleInputChange}
                    prevStep={prevStep}
                    handleSubmit={() => console.log('Form submitted:', formData)}
                />;
            default:
                return null;

        }
    };

    return (
        <div className="flex min-h-screen bg-gray-50">
            {/* Progress Bar */}
            <ProgressBar currentStep={currentStep} />

            {/* Form Content */}
            <div className="flex-1 p-8 content-center">
                <div className="max-w-3xl mx-auto">
                    {renderStep()}
                </div>
            </div>
        </div>
    );
};

export default StepForm; 