// import React, { useState, useEffect } from 'react';
// import { Plus, Edit, Trash2 } from 'lucide-react';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

// // Rich Text Editor Configuration
// const modules = {
//   toolbar: [
//     [{ 'header': [1, 2, false] }],
//     ['bold', 'italic', 'underline', 'strike', 'blockquote'],
//     [{'list': 'ordered'}, {'list': 'bullet'}],
//     ['link'],
//     ['clean']
//   ],
// };

// const formats = [
//   'header',
//   'bold', 'italic', 'underline', 'strike', 'blockquote',
//   'list', 'bullet',
//   'link'
// ];

// // Main App Component
// const CourseHomePage = () => {
//   const [currentPage, setCurrentPage] = useState('list');
//   const [selectedCourse, setSelectedCourse] = useState(null);

//   const navigateTo = (page, course = null) => {
//     setCurrentPage(page);
//     setSelectedCourse(course);
//   };

//   return (
//     <div className="p-4">
//       <nav className="mb-8">
//         <div className="flex justify-between items-center">
//           <h1 className="text-2xl font-bold">Course Management</h1>
//           {currentPage === 'list' && (
//             <button 
//               onClick={() => navigateTo('create')}
//               className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
//             >
//               <Plus size={16} /> Create Course
//             </button>
//           )}
//           {(currentPage === 'create' || currentPage === 'edit') && (
//             <button 
//               onClick={() => navigateTo('list')}
//               className="px-4 py-2 border rounded hover:bg-gray-100"
//             >
//               Back to List
//             </button>
//           )}
//         </div>
//       </nav>

//       {currentPage === 'list' && (
//         <CourseList onEdit={(course) => navigateTo('edit', course)} />
//       )}
//       {currentPage === 'create' && (
//         <CreateCourse onSuccess={() => navigateTo('list')} />
//       )}
//       {currentPage === 'edit' && (
//         <EditCourse course={selectedCourse} onSuccess={() => navigateTo('list')} />
//       )}
//     </div>
//   );
// };

// // Create Course Component
// const CreateCourse = ({ onSuccess }) => {
//   const [formData, setFormData] = useState({
//     course_type: 'monthly',
//     name: '',
//     subtitle: '',
//     category: 'pre_sea',
//     sub_category: 'basic',
//     description: '',
//     seats_availability: [],
//     terms_conditions: ''
//   });

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await fetch('https://api.rightships.com/course/create', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(formData),
//       });
//       if (response.ok) {
//         onSuccess();
//       }
//     } catch (error) {
//       console.error('Error creating course:', error);
//     }
//   };

//   return (
//     <div className="bg-white p-6 rounded-lg shadow-md max-w-4xl mx-auto">
//       <h2 className="text-xl font-bold mb-4">Create New Course</h2>
//       <form onSubmit={handleSubmit} className="space-y-4">
//         <div>
//           <label className="block text-sm font-medium mb-1">Course Type</label>
//           <select
//             className="w-full p-2 border rounded"
//             value={formData.course_type}
//             onChange={(e) => setFormData({ ...formData, course_type: e.target.value })}
//           >
//             <option value="monthly">Monthly</option>
//             <option value="annually">Annually</option>
//           </select>
//         </div>

//         <div>
//           <label className="block text-sm font-medium mb-1">Course Name</label>
//           <input
//             type="text"
//             className="w-full p-2 border rounded"
//             value={formData.name}
//             onChange={(e) => setFormData({ ...formData, name: e.target.value })}
//           />
//         </div>

//         <div>
//           <label className="block text-sm font-medium mb-1">Subtitle</label>
//           <input
//             type="text"
//             className="w-full p-2 border rounded"
//             value={formData.subtitle}
//             onChange={(e) => setFormData({ ...formData, subtitle: e.target.value })}
//           />
//         </div>

//         <div>
//           <label className="block text-sm font-medium mb-1">Category</label>
//           <select
//             className="w-full p-2 border rounded"
//             value={formData.category}
//             onChange={(e) => setFormData({ ...formData, category: e.target.value })}
//           >
//             <option value="pre_sea">Pre Sea</option>
//             <option value="advanced_training">Advanced Training</option>
//           </select>
//         </div>

//         <div>
//           <label className="block text-sm font-medium mb-1">Description</label>
//           <div className="h-64">
//             <ReactQuill
//               theme="snow"
//               value={formData.description}
//               onChange={(content) => setFormData({ ...formData, description: content })}
//               modules={modules}
//               formats={formats}
//               className="h-48"
//             />
//           </div>
//         </div>

//         {formData.course_type === 'monthly' && (
//           <div>
//             <div className="flex justify-between items-center mb-2">
//               <label className="block text-sm font-medium">Seats Availability</label>
//               <button 
//                 type="button"
//                 onClick={() => {
//                   setFormData({
//                     ...formData,
//                     seats_availability: [...formData.seats_availability, { date: '', available_seats: '' }]
//                   });
//                 }}
//                 className="text-blue-500 hover:text-blue-700 text-sm"
//               >
//                 + Add Date
//               </button>
//             </div>
//             <div className="space-y-2">
//               {formData.seats_availability.map((seat, index) => (
//                 <div key={index} className="flex gap-2 items-center">
//                   <input
//                     type="date"
//                     className="flex-1 p-2 border rounded"
//                     value={seat.date || ''}
//                     onChange={(e) => {
//                       const newSeats = [...formData.seats_availability];
//                       newSeats[index] = { ...newSeats[index], date: e.target.value };
//                       setFormData({ ...formData, seats_availability: newSeats });
//                     }}
//                   />
//                   <input
//                     type="number"
//                     className="flex-1 p-2 border rounded"
//                     placeholder="Available Seats"
//                     value={seat.available_seats || ''}
//                     onChange={(e) => {
//                       const newSeats = [...formData.seats_availability];
//                       newSeats[index] = { ...newSeats[index], available_seats: parseInt(e.target.value) };
//                       setFormData({ ...formData, seats_availability: newSeats });
//                     }}
//                   />
//                   <button
//                     type="button"
//                     onClick={() => {
//                       const newSeats = formData.seats_availability.filter((_, i) => i !== index);
//                       setFormData({ ...formData, seats_availability: newSeats });
//                     }}
//                     className="p-2 text-red-500 hover:text-red-700"
//                   >
//                     <Trash2 size={16} />
//                   </button>
//                 </div>
//               ))}
//             </div>
//           </div>
//         )}

//         {formData.course_type === 'annually' && (
//           <div>
//             <label className="block text-sm font-medium mb-1">Terms and Conditions</label>
//             <div className="h-64">
//               <ReactQuill
//                 theme="snow"
//                 value={formData.terms_conditions}
//                 onChange={(content) => setFormData({ ...formData, terms_conditions: content })}
//                 modules={modules}
//                 formats={formats}
//                 className="h-48"
//               />
//             </div>
//           </div>
//         )}

//         <button
//           type="submit"
//           className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 mt-16"
//         >
//           Create Course
//         </button>
//       </form>
//     </div>
//   );
// };

// // Course List Component
// const CourseList = ({ onEdit }) => {
//   const [courses, setCourses] = useState([]);

//   const fetchCourses = async () => {
//     try {
//       const response = await fetch('https://api.rightships.com/course/get', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({}),
//       });
//       if (response.ok) {
//         const data = await response.json();
//         setCourses(data.courses || []);
//       }
//     } catch (error) {
//       console.error('Error fetching courses:', error);
//     }
//   };

//   useEffect(() => {
//     fetchCourses();
//   }, []);

//   const handleDelete = async (courseId) => {
//     try {
//       const response = await fetch('https://api.rightships.com/course/delete', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ course_id: courseId }),
//       });
//       if (response.ok) {
//         fetchCourses();
//       }
//     } catch (error) {
//       console.error('Error deleting course:', error);
//     }
//   };

//   return (
//     <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
//       {courses.map((course) => (
//         <div key={course._id} className="bg-white p-4 rounded-lg shadow">
//           <div className="flex justify-between items-start mb-4">
//             <div>
//               <h3 className="text-lg font-semibold">{course.name}</h3>
//               <p className="text-sm text-gray-500">{course.subtitle}</p>
//               <p className="text-xs text-gray-500 mt-1">{course.course_type}</p>
//             </div>
//             <div className="flex gap-2">
//               <button 
//                 onClick={() => onEdit(course)}
//                 className="p-2 text-blue-500 hover:bg-blue-50 rounded"
//               >
//                 <Edit size={16} />
//               </button>
//               <button 
//                 onClick={() => handleDelete(course._id)}
//                 className="p-2 text-red-500 hover:bg-red-50 rounded"
//               >
//                 <Trash2 size={16} />
//               </button>
//             </div>
//           </div>
//           <div 
//             className="text-sm text-gray-600 mb-4"
//             dangerouslySetInnerHTML={{ __html: course.description }}
//           />
//           {course.course_type === 'monthly' && course.seats_availability?.length > 0 && (
//             <div>
//               <p className="text-sm font-medium mb-2">Available Seats:</p>
//               {course.seats_availability.map((seat, index) => (
//                 <p key={index} className="text-sm text-gray-600">
//                   {new Date(seat.date).toLocaleDateString()}: {seat.available_seats} seats
//                 </p>
//               ))}
//             </div>
//           )}
//         </div>
//       ))}
//     </div>
//   );
// };

// // Edit Course Component
// const EditCourse = ({ course, onSuccess }) => {
//   const [formData, setFormData] = useState({
//     course_id: course._id,
//     course_type: course.course_type,
//     name: course.name,
//     subtitle: course.subtitle || '',
//     category: course.category,
//     sub_category: course.sub_category,
//     description: course.description,
//     seats_availability: course.seats_availability || [],
//     terms_conditions: course.terms_conditions
//   });

//   const handleUpdateSeats = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await fetch('https://api.rightships.com/course/update_seats', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           course_id: formData.course_id,
//           seats_availability: formData.seats_availability,
//         }),
//       });
//       if (response.ok) {
//         onSuccess();
//       }
//     } catch (error) {
//       console.error('Error updating seats:', error);
//     }
//   };

//   return (
//     <div className="bg-white p-6 rounded-lg shadow-md max-w-4xl mx-auto">
//       <h2 className="text-xl font-bold mb-4">Edit Course</h2>
//       <form onSubmit={handleUpdateSeats} className="space-y-4">
//         <div>
//           <label className="block text-sm font-medium mb-1">Course Name</label>
//           <input
//             type="text"
//             className="w-full p-2 border rounded bg-gray-50"
//             value={formData.name}
//             disabled
//           />
//         </div>

//         <div>
//           <label className="block text-sm font-medium mb-1">Subtitle</label>
//           <input
//             type="text"
//             className="w-full p-2 border rounded bg-gray-50"
//             value={formData.subtitle}
//             disabled
//           />
//         </div>

//         <div>
//           <label className="block text-sm font-medium mb-1">Description</label>
//           <div className="h-64">
//             <ReactQuill
//               theme="snow"
//               value={formData.description}
//               readOnly={true}
//               modules={{ toolbar: false }}
//               className="h-48"
//             />
//           </div>
//         </div>

//         {formData.course_type === 'monthly' && (
//           <div>
//             <div className="flex justify-between items-center mb-2">
//               <label className="block text-sm font-medium">Update Seats Availability</label>
//               <button 
//                 type="button"
//                 onClick={() => {
//                   setFormData({
//                     ...formData,
//                     seats_availability: [...formData.seats_availability, { date: '', available_seats: '' }]
//                   });
//                 }}
//                 className="text-blue-500 hover:text-blue-700 text-sm"
//               >
//                 + Add Date
//               </button>
//             </div>
//             <div className="space-y-2">
//               {formData.seats_availability.map((seat, index) => (
//                 <div key={index} className="flex gap-2 items-center">
//                   <input
//                     type="date"
//                     className="flex-1 p-2 border rounded"
//                     value={seat.date || ''}
//                     onChange={(e) => {
//                       const newSeats = [...formData.seats_availability];
//                       newSeats[index] = { ...newSeats[index], date: e.target.value };
//                       setFormData({ ...formData, seats_availability: newSeats });
//                     }}
//                   />
//                   <input
//                     type="number"
//                     className="flex-1 p-2 border rounded"
//                     placeholder="Available Seats"
//                     value={seat.available_seats || ''}
//                     onChange={(e) => {
//                       const newSeats = [...formData.seats_availability];
//                       newSeats[index] = { ...newSeats[index], available_seats: parseInt(e.target.value) };
//                       setFormData({ ...formData, seats_availability: newSeats });
//                     }}
//                   />
//                   <button
//                     type="button"
//                     onClick={() => {
//                       const newSeats = formData.seats_availability.filter((_, i) => i !== index);
//                       setFormData({ ...formData, seats_availability: newSeats });
//                     }}
//                     className="p-2 text-red-500 hover:text-red-700"
//                   >
//                     <Trash2 size={16} />
//                   </button>
//                 </div>
//               ))}
//             </div>
//           </div>
//         )}

//         <button
//           type="submit"
//           className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 mt-16"
//         >
//           Update Course
//         </button>
//       </form>
//     </div>
//   );
// };

// export default CourseHomePage;




import React, { useState, useEffect } from 'react';
import { PlusCircle, Edit2, Trash2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const ListCourses = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const fetchCourses = async (page = 1) => {
    setLoading(true);
    try {
      const response = await fetch('https://api.rightships.com/course/get', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ page, limit: 10 })
      });
      const data = await response.json();
      if (data.code === 200) {
        setCourses(data.courses);
        setTotalPages(Math.ceil(data.total_documents / 10));
      } else {
        setError(data.msg || 'Failed to fetch courses');
      }
    } catch (err) {
      setError('Failed to fetch courses');
    } finally {
      setLoading(false);
    }
  };

  const deleteCourse = async (courseId) => {
    if (!window.confirm('Are you sure you want to delete this course?')) return;

    try {
      const response = await fetch('https://api.rightships.com/course/delete', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ course_id: courseId })
      });
      const data = await response.json();
      if (data.code === 200) {
        setCourses(courses.filter(course => course.course_id !== courseId));
      } else {
        setError(data.msg || 'Failed to delete course');
      }
    } catch (err) {
      setError('Failed to delete course');
    }
  };

  useEffect(() => {
    fetchCourses(currentPage);
  }, [currentPage]);

  if (loading) {
    return <div className="flex justify-center items-center h-64">Loading...</div>;
  }

  return (
    <div className="container mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Courses</h1>
        <button
          onClick={() => navigate('/courses/create')}
          className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          <PlusCircle className="w-4 h-4" />
          Add Course
        </button>
      </div>

      {error && (
        <div className="bg-red-50 text-red-700 p-4 rounded-lg mb-6">
          {error}
        </div>
      )}

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Title
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Type
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Category
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Group
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {courses.map((course) => (
              <tr key={course.course_id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div>
                    <div className="text-sm font-medium text-gray-900">
                      {course.title}
                    </div>
                    <div className="text-sm text-gray-500">{course.subtitle}</div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                    {course.course_type}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {course.category_name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {course.group_name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <div className="flex justify-end gap-2">
                    <button
                      onClick={() => navigate(`/courses/edit/${course.course_id}`)}
                      className="text-blue-600 hover:text-blue-900"
                    >
                      <Edit2 className="w-4 h-4" />
                    </button>
                    <button
                      onClick={() => deleteCourse(course.course_id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-center space-x-2 mt-6">
        <button
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="px-4 py-2 border rounded-md disabled:opacity-50"
        >
          Previous
        </button>
        <span className="px-4 py-2">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          className="px-4 py-2 border rounded-md disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ListCourses;