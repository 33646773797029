import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { X, Edit2 } from 'lucide-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditCourse = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    subtitle: '',
    description: '',
    course_type: '',
    category_id: '',
    group_id: '',
    enquiries_email: '',
    whatsapp_number: '',
    terms_conditions: '',
    seats_availability: []
  });

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'align': [] }],
      [{ 'color': [] }, { 'background': [] }],
      ['link', 'image'],
      ['clean']
    ]
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'align',
    'color', 'background',
    'link', 'image'
  ];

  const fetchCourse = async () => {
    try {
      const response = await fetch('https://api.rightships.com/course/get', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ course_id: id })
      });
      const data = await response.json();
      if (data.code === 200 && data.courses.length > 0) {
        const course = data.courses[0];
        setFormData(course);
        fetchGroups(course.category_id);
      }
    } catch (error) {
      setError('Error fetching course details');
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch('https://api.rightships.com/courseCategory/get', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          page: 1,
          limit: 100,
          is_parent: true
        })
      });
      const data = await response.json();
      if (data.code === 200) {
        setCategories(data.categories);
      }
    } catch (error) {
      setError('Error fetching categories');
    }
  };

  const fetchGroups = async (categoryId) => {
    if (!categoryId) return;
    try {
      const response = await fetch('https://api.rightships.com/courseCategoryGroup/get', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          category_id: categoryId,
          page: 1,
          limit: 100
        })
      });
      const data = await response.json();
      if (data.code === 200) {
        setGroups(data.groups);
      }
    } catch (error) {
      setError('Error fetching groups');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { _id, ...updateData } = formData;
      const response = await fetch('https://api.rightships.com/course/edit', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...updateData, course_id: id })
      });
      const data = await response.json();
      if (data.code === 200) {
        navigate('/courses');
      } else {
        setError(data.msg || 'Failed to update course');
      }
    } catch (error) {
      setError('Error updating course');
    }
  };

  const handleCategoryChange = (categoryId) => {
    setFormData(prev => ({ ...prev, category_id: categoryId, group_id: '' }));
    fetchGroups(categoryId);
  };

  useEffect(() => {
    fetchCategories();
    fetchCourse();
  }, [id]);

  if (loading) {
    return <div className="flex justify-center items-center h-64">Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Edit Course</h1>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded mb-6">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium mb-1">Course Type</label>
            <select
              value={formData.course_type}
              className="w-full border rounded-md px-3 py-2"
              required
              disabled
            >
              <option value="annual">Annual</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Category</label>
            <select
              value={formData.category_id}
              onChange={(e) => handleCategoryChange(e.target.value)}
              className="w-full border rounded-md px-3 py-2"
              required
            >
              <option value="">Select Category</option>
              {categories.map(category => (
                <option key={category.category_id} value={category.category_id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {formData.category_id && (
          <div>
            <label className="block text-sm font-medium mb-1">Group (Optional)</label>
            <select
              value={formData.group_id}
              onChange={(e) => setFormData({ ...formData, group_id: e.target.value })}
              className="w-full border rounded-md px-3 py-2"
            >
              <option value="">Select Group</option>
              {groups.map(group => (
                <option key={group.group_id} value={group.group_id}>
                  {group.name}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium mb-1">Title</label>
            <input
              type="text"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              className="w-full border rounded-md px-3 py-2"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Subtitle (Optional)</label>
            <input
              type="text"
              value={formData.subtitle}
              onChange={(e) => setFormData({ ...formData, subtitle: e.target.value })}
              className="w-full border rounded-md px-3 py-2"
            />
          </div>
        </div>

        {formData.course_type === 'annual' && (
          <div>
            <label className="block text-sm font-medium mb-1">Description</label>
            <div className="border rounded-md h-96">
              <ReactQuill
                theme="snow"
                value={formData.description}
                onChange={(value) => setFormData({ ...formData, description: value })}
                modules={modules}
                formats={formats}
                className="h-80"
              />
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium mb-1">Enquiries Email (Optional)</label>
            <input
              type="email"
              value={formData.enquiries_email}
              onChange={(e) => setFormData({ ...formData, enquiries_email: e.target.value })}
              className="w-full border rounded-md px-3 py-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">WhatsApp Number (Optional)</label>
            <input
              type="text"
              value={formData.whatsapp_number}
              onChange={(e) => setFormData({ ...formData, whatsapp_number: e.target.value })}
              className="w-full border rounded-md px-3 py-2"
            />
          </div>
        </div>

        {formData.course_type === 'monthly' && (
          <div>
            <label className="block text-sm font-medium mb-1">Seat Availability</label>
            <div className="space-y-2">
              {formData.seats_availability.map((seat, index) => (
                <div key={index} className="flex items-center justify-between bg-gray-50 p-3 rounded">
                  <div className="flex gap-4">
                    <input
                      type="date"
                      value={seat.date}
                      onChange={(e) => {
                        const newSeats = [...formData.seats_availability];
                        newSeats[index] = { ...seat, date: e.target.value };
                        setFormData({ ...formData, seats_availability: newSeats });
                      }}
                      className="border rounded-md px-3 py-2"
                    />
                    <input
                      type="number"
                      value={seat.seats}
                      onChange={(e) => {
                        const newSeats = [...formData.seats_availability];
                        newSeats[index] = { ...seat, seats: parseInt(e.target.value) };
                        setFormData({ ...formData, seats_availability: newSeats });
                      }}
                      className="border rounded-md px-3 py-2"
                      min="1"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      const newSeats = formData.seats_availability.filter((_, i) => i !== index);
                      setFormData({ ...formData, seats_availability: newSeats });
                    }}
                    className="text-red-500 hover:text-red-700"
                  >
                    <X className="w-4 h-4" />
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => {
                  setFormData({
                    ...formData,
                    seats_availability: [...formData.seats_availability, { date: '', seats: 1 }]
                  });
                }}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Add Seat
              </button>
            </div>
          </div>
        )}

        <div className="flex justify-end gap-4 pt-6">
          <button
            type="button"
            onClick={() => navigate('/courses')}
            className="px-4 py-2 border rounded-md hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Update Course
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditCourse;