import React, { useState } from 'react';
import PromoCompaniesList from './PromoCompaniesList';
import PromoCompanyForm from './promoCompanyForm';


const PromoCompanyManager = () => {
  const [showForm, setShowForm] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const handleAdd = () => {
    setSelectedCompany(null);
    setShowForm(true);
  };

  const handleEdit = (company) => {
    setSelectedCompany(company);
    setShowForm(true);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <PromoCompaniesList onAdd={handleAdd} onEdit={handleEdit} />
      
      {showForm && (
        <PromoCompanyForm
          company={selectedCompany}
          onClose={() => setShowForm(false)}
        //   onSuccess={handleSuccess}
        />
      )}
    </div>
  );
};

export default PromoCompanyManager;